<template>
  <ion-page>
    <ion-header>
      <ion-toolbar class="toolbar-header">
        <div class="d-flex align-center px-2 my-2">
          <ion-buttons slot="start">
            <ion-back-button
              color="primary"
              mode="ios"
              text=""
              default-href="/b2b/main/home"
              @click="onTapBack()"
              :icon="chevronBackOutline"
            ></ion-back-button>
          </ion-buttons>
          <ion-label class="fw-600 pl-1 fs-3" @click="onTapBack()">{{ $t('notifications') }}</ion-label>
        </div>
        <!-- // NOT IN 1.4 RELEASE SCOPE  -->
        <!-- <ion-icon
          :icon="NOTIFICATION_CATEGORY_ICON_PATH.SETTINGS"
          slot="end"
          color="primary"
          class="mr-3 i-medium"
        ></ion-icon> -->
      </ion-toolbar>
    </ion-header>
    <ion-content :scroll-events="true">
      <ion-refresher slot="fixed" @ionRefresh="handleRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <pull-refresh v-if="!isOnline"></pull-refresh>
      <skeleton-notifications v-if="isLoading" />
      <div v-else>
        <category-notif :notifOptions="notifOptions" />
        <comp-mark-all-as-read />
        <all-notifications
          :notificationsList="notificationsList"
          :isDisabledLoadMore="isDisabledLoadMore"
          :isMerchantUser="true"
          @loadMore="loadMore"
        />
      </div>
    </ion-content>
  </ion-page>
</template>
<script>
import AllNotifications from '@/modules/shared/components/notifications/AllNotifications.vue';
import CategoryNotif from '@/modules/shared/components/notifications/CategoryNotif.vue';
import CompMarkAllAsRead from '@/modules/shared/components/notifications/CompMarkAllAsRead.vue';
import SkeletonNotifications from '@/modules/shared/components/notifications/SkeletonNotifications.vue';

import { usePullToRefreshPage } from '@/usecases/global';
import { useNotificationAllPage } from '@/usecases/notifications';
import { defineAsyncComponent, defineComponent } from 'vue';
export default defineComponent({
  name: 'B2bNotificationList',
  components: {
    CategoryNotif,
    SkeletonNotifications,
    AllNotifications,
    CompMarkAllAsRead,
    PullRefresh: defineAsyncComponent(() => import('@/modules/shared/views/refreshPage/PullRefresh.vue'))
  },
  setup() {
    const {
      loadMore,
      notificationsList,
      isDisabledLoadMore,
      chevronBackOutline,
      NOTIFICATION_CATEGORY_ICON_PATH,
      isLoading,
      fetchData,
      notifOptions,
      onTapBack
    } = useNotificationAllPage();
    const { isOnline, handleRefreshAndRefetchData } = usePullToRefreshPage();
    const handleRefresh = (event) => {
      handleRefreshAndRefetchData(event, fetchData);
    };
    return {
      onTapBack,
      isLoading,
      notificationsList,
      chevronBackOutline,
      isOnline,
      loadMore,
      isDisabledLoadMore,
      notifOptions,
      NOTIFICATION_CATEGORY_ICON_PATH,
      handleRefresh
    };
  }
});
</script>
<style lang="scss" scoped>
.i-medium {
  font-size: 24px;
}
</style>
